<template>
  <div class="subject">
    <div style="margin-bottom: 40px">
      <a-button type="primary" icon="plus" @click="showModal = true">
        添加被检单位
      </a-button>
    </div>

    <div style="margin-bottom: 8px; font-size: 14px">
      共有{{ pagination.total }}条数据
    </div>
    <a-table
      style="background-color: #fff"
      rowKey="id"
      bordered
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="action" slot-scope="record">
        <a-button type="danger" @click="delSubject(record)">删除</a-button>
      </span>
    </a-table>

    <a-modal
      :width="800"
      v-model="showModal"
      title="添加被检测单位"
      @ok="handleSubmit"
    >
      <a-form :form="form">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="单位名称"
        >
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '名称必填' }] },
            ]"
            placeholder="单位名称"
          />
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="联系方式"
        >
          <a-input
            v-decorator="[
              'contact_phone',
              { rules: [{ required: true, message: '联系方式' }] },
            ]"
            placeholder="联系方式"
          />
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="联系方式"
        >
          <a-input
            v-decorator="[
              'address',
              { rules: [{ required: true, message: '地址' }] },
            ]"
            placeholder="地址"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { list, addSubject, delSubject } from "@/api/subject";
import { message } from "ant-design-vue";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "单位名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "联系方式",
    dataIndex: "contact_phone",
    key: "contact_phone",
  },
  {
    title: "地址",
    dataIndex: "address",
    key: "address",
    ellipsis: true,
  },
  {
    title: "加入时间",
    dataIndex: "create_time",
    key: "create_time",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18, offset: 4 },
};
export default {
  name: "Subject",
  data() {
    return {
      data: [],
      columns,
      showModal: false,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
    };
  },
  methods: {
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.loadData(this.pagination);
    },

    loadData() {
      list(this.pagination.current, this.pagination.pageSize).then((res) => {
        this.pagination.total = res.data.total;
        this.data = res.data.rows || [];
      });
    },

    delSubject(record) {
      delSubject(record.id).then((res) => {
        if (res.code === 200) {
          message.success("删除成功");
          this.loadData();
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          addSubject(values).then((res) => {
            if (res.code === 200) {
              message.success("添加成功");
              this.form.resetFields();
              this.loadData();
              this.showModal = false;
            }
          });
        }
      });
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>