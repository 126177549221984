import axios from '../utils/axios';

export function list(current, size) {
    const data = {
        current, size
    }
    return axios.post('/subject/list', data)
}

export function addSubject(item) {
    const data = {
        name: item.name,
        contact_phone: item.contact_phone,
        address: item.address
    }
    return axios.post('/subject/add', data)
}

export function delSubject(id) {
    const data = {
        id
    }
    return axios.post('/subject/del', data)
}